import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { Loader } from "../Loader";

const OutcomeBox = styled.div`
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ababab;
`;
const Headline = styled.h4`
  font-size: 20px;
`;
const OutcomeText = styled.p`
  white-space: break-spaces;
`;

const OutcomePage = ({ outcome, isLoading, loaderColor }) => {
  return (
    <div className="container-fluid">
      <Row className="justify-content-center mt-5">
        <Col lg={5} sm={8} xs={12}>
          <OutcomeBox className="p-5 text-center">
            {isLoading ? (
              <Loader scale={1.2} color={loaderColor} />
            ) : (
              <>
                <Headline className="fw-bold mb-4">
                  {outcome.outcomeHeadline}
                </Headline>
                <OutcomeText>{outcome.outcomeText}</OutcomeText>
              </>
            )}
          </OutcomeBox>
        </Col>
      </Row>
    </div>
  );
};

export default OutcomePage;
