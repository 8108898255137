import { string, object, boolean } from "yup";

const alphabetsOnlyRegex = "a-zA-Z";
const specialCharacters = "ÄäÖöÜüß-";
const nameFieldRegex = new RegExp(
  `^[${alphabetsOnlyRegex}${specialCharacters}\\s]*$`
);

export const AnswerFormSchema = object().shape({
  answer: string().required("This field can't be empty")
});

export const ContactFormBaseSchema = object().shape({
  name: string()
    .required("Name ist erforderlich")
    .matches(nameFieldRegex, "Ungültige Zeicheneingabe"),
  email: string()
    .email("Geben Sie eine gültige Email ein")
    .required("Email ist erforderlich")
});
export const ContactPhoneSchema = object().shape({
  phone: string().required("Telefonnummer ist erforderlich").nullable(),
  policyCheck: boolean().when("policySectionDisplayDecider", {
    is: true,
    then: boolean()
      .required("The terms and conditions must be accepted.")
      .oneOf([true], "The terms and conditions must be accepted."),
    otherwise: boolean().notRequired()
  })
});

export const ContactFormCompleteSchema =
  ContactFormBaseSchema.concat(ContactPhoneSchema);
