import { useDispatch, useSelector } from "react-redux";
import { recordWebsiteVisit } from "../services/api";
import { updateModalData } from "../store/slices/funnelInfo";
import { APP_VISIT_TYPES } from "../templates/strings";

export const useModal = () => {
  const dispatch = useDispatch();
  const { funnel } = useSelector((state) => state.funnelInfo);

  const handleModalData = async (data) => {
    try {
      const userId = localStorage.getItem("senderId");
      await recordWebsiteVisit({
        userId,
        funnelId: funnel.id,
        funnelVersion: funnel.activeVersion,
        type: APP_VISIT_TYPES.WEBSITE
      });
    } catch (e) {
      console.log(e);
    }
    if (!data.content) return;
    dispatch(updateModalData(data));
  };

  return { handleModalData };
};
