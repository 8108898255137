import { useState } from "react";
import * as Icons from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { faEnvelope, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import {
  USPItem,
  USPLabel,
  Progress,
  HeaderLink,
  HeaderIcon,
  ProgressInfo,
  ModernHeader,
  USPContainer,
  QuestionText,
  BackgroundDiv,
  ModernHeadline,
  HeaderLinkLabel,
  ButtonContainer,
  ModernContainer,
  MainCenteredDiv,
  CenteredContent,
  QuestionSubtext,
  TaglineContainer,
  AnswersContainer,
  ProgressContainer,
  TaglineContentFirst,
  TaglineContentSecond,
  ModernFunnelHeadline,
  ModernVariantContainer,
  ModernAnimationContainer,
  HeadlineAndTaglineContainer,
  FunnelHeadlineAndQuestionContainer
} from "./ModernVariant.styles";
import FooterComponent from "./Footer/footer";
import OutcomeComponent from "./Outcome/outcome";
import ModernLeadForm from "./LeadForm/lead-form";
import ThankyouComponent from "./Thankyou/thankyou";
import ModernZipComponent from "./ZipCode/zip-code";
import { HeaderImage } from "../Header/Header.styles";
import ZipCodeErrorComponent from "./ZipCode/zip-error";
import MoveBackButton from "./MoveButtons/move-back-button";
import { useAnswerAction } from "../../hooks/useAnswerAction";
import SliderAnswerComponent from "./AnswerTypes/slider-answer";
import { useZipcodeAction } from "../../hooks/useZipcodeAction";
import IconAndTextAnsComponent from "./AnswerTypes/icon-and-text";
import MoveForwardButton from "./MoveButtons/move-forward-button";
import FreeTextAnswerComponent from "./AnswerTypes/free-text-answer";
import {
  APP_VISIT_TYPES,
  QUESTION_TYPE,
  THUMBNAIL_TYPE
} from "../../templates/strings";
import { goBack } from "../../store/slices/funnelInfo";
import { recordWebsiteVisit } from "../../services/api";

const ModernVariant = ({ question, progress, isTestMode }) => {
  const dispatch = useDispatch();
  const { funnel } = useSelector((state) => state.funnelInfo);
  const { funnelInfo, zipInfo } = useSelector((state) => state);
  const { handleNextCustom, handleFreeTextSubmit } = useAnswerAction();
  const { handleZipcodeSubmit, handleCheckZipcode } = useZipcodeAction();
  const [sliderRangeValue, setSliderRangeValue] = useState(0);
  const [freeTextValue, setFreeTextValue] = useState();
  const [leadError, setLeadError] = useState(false);
  const [isLeadSubmitting, setIsLeadSubmitting] = useState(false);

  const progressBarCss = `
            .bg-progress_color{
              background-color: ${funnel.design?.baseColor} !important;
            }
          `;

  const CustomFaIconElement = ({ config: { iconName, color } }) => {
    const FaIcon = Icons[iconName];
    if (!FaIcon) return <p>Icon not found!</p>;
    return <FaIcon style={{ color, fontSize: "30px" }} />;
  };

  const handleZipSubmit = async () => {
    handleZipcodeSubmit();
    await handleCheckZipcode();
  };
  const handleAnswerNext = () => {
    if (question?.type === QUESTION_TYPE.FREE_TEXT_FIELD) {
      handleFreeTextSubmit({
        question,
        selectedOptionValue: freeTextValue
      });
      if (freeTextValue) {
        setFreeTextValue("");
      }
    } else {
      handleNextCustom({
        question,
        sliderVal: sliderRangeValue
      });
      if (sliderRangeValue) {
        setSliderRangeValue(0);
      }
    }
  };
  const isNextDisabled = () => {
    let isDisabled = false;
    if (
      (question?.type === QUESTION_TYPE.SLIDER &&
        (sliderRangeValue === 0 || sliderRangeValue < question?.range?.min)) ||
      (question?.type === QUESTION_TYPE.FREE_TEXT_FIELD &&
        Boolean(!freeTextValue)) ||
      (question?.isMultiSelectable &&
        !Object.keys(funnelInfo.selectedAnswers).length)
    ) {
      isDisabled = true;
    }
    return isDisabled;
  };

  const recordVisit = async (type) => {
    if (isTestMode) return;
    try {
      const userId = localStorage.getItem("senderId");
      await recordWebsiteVisit({
        userId,
        funnelId: funnel.id,
        funnelVersion: funnel.activeVersion,
        type
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ModernVariantContainer className="position-relative">
      <style>{progressBarCss}</style>
      <div className="bg-white sticky-top">
        <ModernHeader className="d-flex align-items-center justify-content-between py-4 mx-auto">
          <div>
            <HeaderImage
              alt="company logo"
              scale={funnel.design?.companyLogoScale}
              src={`${process.env.REACT_APP_ASSET_CDN_URL}/${funnel.design?.companyLogo?.key}/${THUMBNAIL_TYPE.COMPANY_LOGO}`}
            />
          </div>
          <div className="d-flex align-items-center">
            <div>
              <HeaderLink
                onClick={() => recordVisit(APP_VISIT_TYPES.PHONE)}
                className="d-flex align-items-center"
                color={funnel.design?.modernHeaderLabelsColor}
                href={`tel:${funnel.design?.modernHeaderPhoneNumber}`}
              >
                <HeaderIcon icon={faPhoneVolume} rotate="true" />
                <HeaderLinkLabel className="d-none d-md-flex">
                  {funnel.design?.modernHeaderCallLabel}
                </HeaderLinkLabel>
              </HeaderLink>
            </div>
            <div className="ms-3 ms-md-5">
              <HeaderLink
                onClick={() => recordVisit(APP_VISIT_TYPES.EMAIL)}
                className="d-flex align-items-center"
                color={funnel.design?.modernHeaderLabelsColor}
                href={`mailto:${funnel.design?.modernHeaderEmailAddress}`}
              >
                <HeaderIcon icon={faEnvelope} />
                <HeaderLinkLabel className="d-none d-md-flex">
                  {funnel.design?.modernHeaderEmailAddress}
                </HeaderLinkLabel>
              </HeaderLink>
            </div>
          </div>
        </ModernHeader>
      </div>
      <ModernContainer className="bg-light">
        <MainCenteredDiv className="mx-auto">
          <CenteredContent
            className="position-relative"
            bgOne={funnel.design?.modernBackgroundOne}
            bgTwo={funnel.design?.modernBackgroundTwo}
          >
            {funnelInfo.outcome?.outcomeLogic === "directly" ||
            (funnelInfo.allQuestions.length === funnelInfo.currentQuestion &&
              funnelInfo.outcome?.outcomeLogic === "end") ? (
              <OutcomeComponent />
            ) : (
              <>
                <ModernHeadline className="mb-0 text-white text-center">
                  {funnel.design?.modernHeadline}
                </ModernHeadline>

                {funnelInfo.currentQuestion <
                  funnelInfo.allQuestions.length && (
                  <HeadlineAndTaglineContainer className="mx-auto d-flex flex-nowrap flex-row align-items-stretch justify-content-brtween">
                    <FunnelHeadlineAndQuestionContainer
                      questionPosition={question?.position}
                      contrast={funnel.design?.modernContrastColor}
                      className="text-white d-flex flex-fill flex-nowrap flex-column justify-content-center"
                    >
                      {question?.position > 0 && (
                        <ProgressContainer className="d-flex align-items-center flex-nowrap">
                          <Progress
                            now={progress}
                            variant="progress_color"
                            className="flex-fill my-1 bg-black"
                          />
                          <ProgressInfo className="d-flex">
                            <div>{question?.position + 1}</div>
                            <div className="mx-1">/</div>
                            <div>{funnel.funnelQA?.length}</div>
                          </ProgressInfo>
                        </ProgressContainer>
                      )}
                      {question?.position === 0 && (
                        <ModernFunnelHeadline className="mb-1">
                          {funnel.design?.modernFunnelHeadline}
                        </ModernFunnelHeadline>
                      )}
                      <QuestionText className="mb-0">
                        {question?.text}
                      </QuestionText>
                      <QuestionSubtext className="mt-1">
                        {question?.subtext}
                      </QuestionSubtext>
                    </FunnelHeadlineAndQuestionContainer>
                    {question?.position === 0 && (
                      <TaglineContainer
                        foreground={funnel.design?.additionalTaglineColor}
                        background={funnel.design?.additionalTaglineBackground}
                        className="d-none d-md-flex flex-nowrap flex-column align-items-center justify-content-center"
                      >
                        <TaglineContentFirst>
                          {funnel.design?.additionalTaglineLabelOne}
                        </TaglineContentFirst>
                        <TaglineContentSecond>
                          {funnel.design?.additionalTaglineLabelTwo}
                        </TaglineContentSecond>
                      </TaglineContainer>
                    )}
                  </HeadlineAndTaglineContainer>
                )}

                <BackgroundDiv
                  bgTwo={funnel.design?.modernBackgroundTwo}
                  className="position-relative"
                >
                  <ModernAnimationContainer key={question?._id}>
                    {funnelInfo.currentQuestion <
                      funnelInfo.allQuestions.length && (
                      <AnswersContainer className="mx-auto d-flex flex-nowrap flex-column flex-md-row align-items-stretch justify-content-center position-relative">
                        {question?.type === QUESTION_TYPE.ICONS ||
                        question?.type === QUESTION_TYPE.TEXTONLY ? (
                          question?.answers.map((opt, optIndex) => (
                            <IconAndTextAnsComponent
                              isPreviouslyChecked={
                                funnelInfo.selectedAnswers[opt._id]
                              }
                              opt={opt}
                              key={optIndex}
                              question={question}
                            />
                          ))
                        ) : question?.type === QUESTION_TYPE.SLIDER ? (
                          <SliderAnswerComponent
                            question={question}
                            setSliderRangeValue={setSliderRangeValue}
                          />
                        ) : question?.type === QUESTION_TYPE.FREE_TEXT_FIELD ? (
                          <FreeTextAnswerComponent
                            setFreeTextValue={setFreeTextValue}
                            freeTextValue={freeTextValue}
                          />
                        ) : (
                          <></>
                        )}
                      </AnswersContainer>
                    )}
                    {funnelInfo.currentQuestion ===
                      funnelInfo.allQuestions.length &&
                      !zipInfo.isSubmitted && <ModernZipComponent />}
                    {zipInfo.isSubmitted &&
                      !funnelInfo.leadForm.isSubmitted &&
                      (zipInfo.filterData?.shouldCreate ? (
                        <ModernAnimationContainer>
                          <ModernLeadForm
                            setLeadError={setLeadError}
                            isLeadSubmitting={isLeadSubmitting}
                            setIsLeadSubmitting={setIsLeadSubmitting}
                          />
                        </ModernAnimationContainer>
                      ) : (
                        <ZipCodeErrorComponent />
                      ))}

                    {funnelInfo.leadForm.isSubmitted &&
                      !funnel.thankyouPage?.redirectLead && (
                        <ModernAnimationContainer>
                          <ThankyouComponent error={leadError} />
                        </ModernAnimationContainer>
                      )}
                  </ModernAnimationContainer>
                  {/* This section will show only on questions of type Text or Icons with multi select true */}
                  {!zipInfo.isSubmitted && (
                    <ButtonContainer className="position-relative d-flex flex-row justify-content-between mx-auto">
                      {question?.position !== 0 && (
                        <MoveBackButton
                          onBackClick={() => dispatch(goBack())}
                          btnBg={funnel.design?.modernContrastColor}
                        />
                      )}

                      {question?.type === QUESTION_TYPE.FREE_TEXT_FIELD ||
                      question?.type === QUESTION_TYPE.SLIDER ||
                      question?.isMultiSelectable ? (
                        // for next answer
                        <MoveForwardButton
                          isDisabled={isNextDisabled()}
                          handleClick={handleAnswerNext}
                          buttonText={funnel.design?.modernNextButtonText}
                          textColor={funnel.design?.modernNextButtonTextColor}
                          btnBg={funnel.design?.modernNextButtonBackground}
                          btnHoverBg={
                            funnel.design?.modernNextButtonHoverBackground
                          }
                        />
                      ) : funnelInfo.currentQuestion ===
                          funnelInfo.allQuestions.length &&
                        !zipInfo.isSubmitted ? (
                        // for submit zipcode
                        <MoveForwardButton
                          handleClick={handleZipSubmit}
                          isDisabled={!zipInfo.value}
                          buttonText={funnel.textElements?.zipCodeScreenButton}
                          textColor={funnel.design?.modernNextButtonTextColor}
                          btnBg={funnel.design?.modernNextButtonBackground}
                          btnHoverBg={
                            funnel.design?.modernNextButtonHoverBackground
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </ButtonContainer>
                  )}
                </BackgroundDiv>
              </>
            )}
          </CenteredContent>

          <USPContainer className="w-100 bg-white d-flex flex-column flex-md-row justify-content-center flex-wrap">
            {funnel.design?.uspIcons?.map((usp, uspIndex) => (
              <USPItem
                key={uspIndex}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <CustomFaIconElement
                  config={{ iconName: usp.icon, color: usp.color }}
                />
                <USPLabel uspColor={usp.color}>{usp.label}</USPLabel>
              </USPItem>
            ))}
          </USPContainer>
        </MainCenteredDiv>
      </ModernContainer>
      <div className="bg-light pt-5">
        <FooterComponent />
      </div>
    </ModernVariantContainer>
  );
};
export default ModernVariant;
