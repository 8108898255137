import { useSelector } from "react-redux";
import {
  FooterImg,
  CompanyDiv,
  FooterImgDiv,
  FooterContainer
} from "./Footer.styles";
import ReadOnlyModal from "../Modal";
import { THUMBNAIL_TYPE } from "../../templates/strings";
import AnchorElement from "../CommonVariantComponents/anchor-element";

export const Footer = () => {
  const { funnel } = useSelector((state) => state.funnelInfo);
  const design = funnel.design;
  const company = funnel.companyInfo;
  const { footerForeground, partnerLogo, partnerLogoDisplay } = design;
  const { key } = partnerLogo || {};
  const {
    companyName,
    imprintUrl,
    imprintContent,
    dataProtectionUrl,
    dataProtectionContent
  } = company;
  const imgSrc = `${process.env.REACT_APP_ASSET_CDN_URL}/${key}/${THUMBNAIL_TYPE.PARTNER_LOGO}`;

  return (
    <>
      <FooterContainer
        footerForeground={footerForeground}
        className="row d-flex align-items-center w-100"
      >
        <CompanyDiv className="col-sm-12 col-md-4 text-center">
          © {companyName} {new Date().getFullYear()}
        </CompanyDiv>
        <FooterImgDiv
          className="col-sm-12 col-md-4 text-center"
          visible={partnerLogoDisplay}
        >
          <a href="https://am-beratung.de" target="_blank" rel="noreferrer">
            <FooterImg src={imgSrc} alt="Footer image" />
          </a>
        </FooterImgDiv>
        <div className="col-sm-12 col-md-4 text-center">
          <AnchorElement
            classes="me-5"
            anchorText="Datenschutz"
            modalData={{
              heading: "Datenschutzerklärung",
              content: dataProtectionContent
            }}
            href={dataProtectionContent ? void 0 : dataProtectionUrl}
          />
          <AnchorElement
            anchorText="Impressum"
            modalData={{
              heading: "Impressum",
              content: imprintContent
            }}
            href={imprintContent ? void 0 : imprintUrl}
          />
        </div>
      </FooterContainer>
      <ReadOnlyModal />
    </>
  );
};
