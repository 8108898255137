import styled from "styled-components";

export const ZipImage = styled.img`
  width: 100%;
  height: 150px;
  @media (max-width: 767px) {
    width: 150px;
  }
`;
export const Para = styled.p`
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-top: 0;
`;
export const PreWrapPara = styled.p`
  white-space: pre-wrap;
`;
