import styled from "styled-components";
import { FOOTER_FOREGROUND } from "../../templates/strings";

export const FooterContainer = styled.div`
  color: #000;
  font-size: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  outline: none;
  margin-top: 100px;
  margin-bottom: 30px;
  color: ${({ footerForeground }) =>
    footerForeground === FOOTER_FOREGROUND.SHADOWED
      ? "#fff"
      : footerForeground};
  text-shadow: ${({ footerForeground }) =>
    footerForeground === FOOTER_FOREGROUND.SHADOWED
      ? "1px 0px 2px #5e5c5c"
      : "none"};
`;

export const CompanyDiv = styled.div`
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

export const FooterImgDiv = styled.div`
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  @media (max-width: 767px) {
    margin-bottom: 50px;
  }
`;

export const FooterImg = styled.img`
  width: 200px;
  height: 100px;
`;
