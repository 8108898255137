import { useState } from "react";
import { useSelector } from "react-redux";
import { Col, Form } from "react-bootstrap";
import {
  Question,
  Progress,
  AnswersContainer,
  AnimationContainer
} from "./Question.styles";
import { TextOption } from "./TextOption";
import FreeTextForm from "../FreeTextForm";
import { ImageOption } from "./ImageOption";
import CustomNextButton from "../CustomNextButton";
import { QUESTION_TYPE } from "../../templates/strings";
import { useAnswerAction } from "../../hooks/useAnswerAction";

export const QuestionComponent = ({ progress, questionData }) => {
  const { funnel } = useSelector((state) => state.funnelInfo);
  const baseColor = funnel.design?.baseColor;
  const nextBtnOpts = funnel.textElements;
  const { handleNextCustom } = useAnswerAction();
  const { funnelInfo } = useSelector((state) => state);
  const [sliderRange, setSliderRange] = useState(questionData?.range?.min || 0);

  const progressBarCss = `
            .bg-progress_color{
              background-color: ${baseColor} !important;
            }
          `;

  const handleNext = () => {
    handleNextCustom({
      sliderVal: "",
      question: questionData
    });
  };

  return (
    <>
      <style>{progressBarCss}</style>
      <Question className="px-2 px-sm-5 mb-0">{questionData?.text}</Question>
      <div className="px-2 px-sm-5 mt-2 fs-5 text-center">
        {questionData?.subtext}
      </div>
      <Progress className="w-50 my-4" variant="progress_color" now={progress} />
      <AnimationContainer key={questionData?._id}>
        <AnswersContainer
          type={questionData?.type}
          className="justify-content-center px-2 px-md-5 container"
        >
          <div className="row">
            {!questionData?.answers.length &&
            questionData?.type === QUESTION_TYPE.SLIDER ? (
              <>
                <Form.Label>Antwort:</Form.Label>
                <Col xs={10}>
                  <Form.Range
                    min={questionData?.range?.min}
                    max={questionData?.range?.max}
                    step={questionData?.range?.step}
                    value={sliderRange}
                    className="align-middle"
                    onChange={({ target: { value } }) => setSliderRange(value)}
                  />
                </Col>
                <Col className="text-end">
                  <div className="fw-bold">{sliderRange}</div>
                </Col>
                <div className="mt-4 d-flex justify-content-end">
                  <CustomNextButton
                    nextBtnOpts={nextBtnOpts}
                    handleClick={() => {
                      handleNextCustom({
                        question: questionData,
                        sliderVal: sliderRange
                      });
                      if (sliderRange) {
                        setSliderRange(0);
                      }
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                {questionData?.type === QUESTION_TYPE.FREE_TEXT_FIELD ? (
                  <FreeTextForm
                    question={questionData}
                    nextBtnOpts={nextBtnOpts}
                  />
                ) : questionData?.type === QUESTION_TYPE.ICONS ? (
                  questionData?.answers.map((opt, ind) => (
                    <Col xs={6} key={ind}>
                      <ImageOption opt={opt} question={questionData} />
                    </Col>
                  ))
                ) : (
                  questionData?.answers.map((opt, ind) => (
                    <Col xs={12} key={ind}>
                      <TextOption opt={opt} question={questionData} />
                    </Col>
                  ))
                )}
              </>
            )}
          </div>
          {questionData?.isMultiSelectable && (
            <div className="mt-3 text-end">
              <CustomNextButton
                nextBtnOpts={nextBtnOpts}
                handleClick={handleNext}
                isDisabled={!Object.keys(funnelInfo.selectedAnswers).length}
              />
            </div>
          )}
        </AnswersContainer>
      </AnimationContainer>
    </>
  );
};
