import { useSelector } from "react-redux";
import {
  AnswerBadge,
  CenteredInput,
  CenteredInputLabel,
  CenteredInnerContainer,
  CenteredUserPromptContainer
} from "../ModernVariant.styles";
import { useEffect } from "react";

const FreeTextAnswerComponent = ({ setFreeTextValue, freeTextValue }) => {
  const { freeTextPrevValue } = useSelector((state) => state.funnelInfo);
  useEffect(() => {
    setFreeTextValue(freeTextPrevValue);
    // eslint-disable-next-line
  }, []);
  return (
    <CenteredUserPromptContainer className="bg-white text-center d-flex flex-column justify-content-center align-items-center">
      <AnswerBadge className="position-absolute">Ihre Antwort</AnswerBadge>
      <CenteredInnerContainer className="d-flex flex-wrap justify-content-between">
        <CenteredInputLabel>Geben Sie Antwort ein</CenteredInputLabel>
        <CenteredInput
          value={freeTextValue}
          type="text"
          placeholder="Hier schreiben"
          onChange={({ target: { value } }) => setFreeTextValue(value)}
        />
      </CenteredInnerContainer>
    </CenteredUserPromptContainer>
  );
};
export default FreeTextAnswerComponent;
