import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ThankyouBtn,
  StickyHeadline,
  ModernSmContainer,
  CenteredInnerContainer,
  CenteredUserPromptContainer
} from "../ModernVariant.styles";
import { loadAnalyticsAfterLeadTrigger } from "../../../services/helper";

const ThankyouComponent = ({ error }) => {
  const { funnel } = useSelector((state) => state.funnelInfo);
  const thankyouPage = funnel.thankyouPage;
  const websiteUrl = funnel.companyInfo?.companyWebsite;
  const thankyouPageScript = funnel.scriptThankyou;

  useEffect(() => {
    if (thankyouPageScript) {
      loadAnalyticsAfterLeadTrigger(thankyouPageScript);
    }
  }, [thankyouPageScript]);

  return (
    <ModernSmContainer>
      <CenteredUserPromptContainer
        height="300"
        className="mt-5 mx-auto bg-white text-center d-flex flex-column justify-content-center align-items-center position-relative"
      >
        <CenteredInnerContainer className="w-100 d-flex flex-wrap justify-content-between">
          <div className="d-flex flex-fill align-items-center gap-3">
            <div className="w-100 text-center">
              {error ? (
                <>
                  <h5 className="fw-bold">Request Failed</h5>
                  <p>{error}</p>
                </>
              ) : (
                <>
                  <StickyHeadline className="sticky-top bg-white py-2 pt-3 mb-0">
                    {thankyouPage?.thankyouPageHeadline}
                  </StickyHeadline>
                  <div className="px-4 pb-4">
                    <div className="fw-normal fs-6">
                      {thankyouPage?.thankyouPageText}
                    </div>
                    {thankyouPage?.websiteButton === "Yes" && (
                      <div className="my-2">
                        <a href={websiteUrl}>
                          <ThankyouBtn
                            background={funnel.design?.modernBackgroundOne}
                          >
                            {thankyouPage?.websiteButtonText}
                          </ThankyouBtn>
                        </a>
                      </div>
                    )}
                    <div className="fw-normal fs-6">
                      {thankyouPage?.greetingPhrase}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </CenteredInnerContainer>
      </CenteredUserPromptContainer>
    </ModernSmContainer>
  );
};
export default ThankyouComponent;
