import { useSelector } from "react-redux";
import { Para, PreWrapPara } from "./ZipCodeForm.styles";

export const ZipCodeError = () => {
  const { filterData } = useSelector((state) => state.zipInfo);
  return (
    <div className="px-3 pt-5 px-md-5">
      <Para>{filterData.heading}</Para>
      <div className="row align-items-center">
        <div className="col-12 text-center">
          <PreWrapPara>{filterData.message}</PreWrapPara>
        </div>
      </div>
    </div>
  );
};
