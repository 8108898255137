import { useDispatch } from "react-redux";
import { updateEmailDomainConfirmation } from "../store/slices/funnelInfo";

export const useEmailDomainConfirmation = () => {
  const dispatch = useDispatch();
  const handleEmailDomainConfirmation = ({ hasError, emailValue }) => {
    if (!hasError && emailValue?.length && emailValue?.includes(".")) {
      const splittedEmail = emailValue.split(".");
      const domain = splittedEmail[splittedEmail.length - 1];
      if (domain === "con") {
        dispatch(updateEmailDomainConfirmation(true));
      } else {
        dispatch(updateEmailDomainConfirmation(false));
      }
    } else {
      dispatch(updateEmailDomainConfirmation(false));
    }
  };
  return { handleEmailDomainConfirmation };
};
