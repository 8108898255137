export const FUNNEL_QA_ACTIONS_ENUM = {
  UPDATE_CURRENT_QUESTION: "UPDATE_CURRENT_QUESTION",
  UPDATE_SELECTED_VALUE: "UPDATE_SELECTED_VALUE",
  UPDATE_QA: "UPDATE_QA",
  UPDATE_OUTCOME: "UPDATE_OUTCOME",
  RESTART_QUESTIONNAIRE: "RESTART_QUESTIONNAIRE"
};

export const QUESTION_POSITION = {
  next: "next",
  last: "last",
  multi: "multi"
};

export const OUTCOME_TYPE = {
  directly: "directly",
  end: "end",
  restart: "restart"
};

export const INSIGHTS_TYPE = {
  ...OUTCOME_TYPE,
  ZIP: "zip",
  QUESTION: "question",
  LEAD_STEP_ONE: "leadStepOne",
  LEAD_CREATED: "leadCreated",
  LEAD: "lead"
};

export const QUESTION_TYPE = {
  ICONS: "ICONS",
  TEXTONLY: "TEXTONLY",
  FREE_TEXT_FIELD: "FREE_TEXT_FIELD",
  SLIDER: "SLIDER"
};

export const FOOTER_FOREGROUND = {
  SHADOWED: "shadowed"
};

export const PHONE_NUMBER_RADIO = {
  STANDARD: "STANDARD",
  LIGHT: "LIGHT",
  VERIFY: "VERIFY"
};

export const THUMBNAIL_TYPE = {
  BACKGROUND: "background", // 1920 x 1080 -> desktop background (for funnel app)
  MOBILE_BACKGROUND: "mobile_background", // 480 x 800 -> mobile background (for funnel app)
  GALLERY_FORM_ASSET: "gallery_form_asset", // 230 x unknown -> gallery form asset
  COMPANY_LOGO: "company_logo", // 580 x unknown -> header logo (for funnel app)
  PARTNER_LOGO: "partner_logo", // 380 x unknown -> footer logo (for funnel app)
  ANSWER_OPTION_ICON: "answer_option_icon", // 214 x unknown -> answer option image
  GALLERY_MODAL_ASSET: "gallery_modal_asset" // 177 x unknown -> gallery modal image
};

export const MOBILE_BACKGROUND_WIDTH = 480;

export const FUNNEL_DESIGNS = {
  CLASSIC: "CLASSIC",
  MODERN: "MODERN"
};

export const APP_VISIT_TYPES = {
  WEBSITE: "WEBSITE",
  PHONE: "PHONE",
  EMAIL: "EMAIL"
};
