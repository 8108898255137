import styled from "styled-components";

export const ImageContainer = styled.div`
  padding: 15px;
  margin: 20px 0;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 0px;
  background-color: ${({ background, logoBgDisplay }) =>
    !logoBgDisplay ? "transparent" : background};
`;
export const HeaderImage = styled.img`
  width: 200px;
  border-radius: 5px;
  zoom: ${({ scale }) => `${scale}%`};
`;
