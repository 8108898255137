import styled from "styled-components";
import { ProgressBar } from "react-bootstrap";

export const Question = styled.p`
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-top: 20px;
`;
export const AnswersContainer = styled.div`
  ${({ type }) =>
    type === "image" &&
    `
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    `}
`;
export const Progress = styled(ProgressBar)`
  height: 10px;
  margin: 0 auto 16px auto;
`;

export const AnimationContainer = styled.div`
  position: relative;
  animation: slideUp 0.5s;
  @keyframes slideUp {
    from {
      right: -40px;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }
`;
