import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateModalData } from "../../store/slices/funnelInfo";

const ReadOnlyModal = () => {
  const dispatch = useDispatch();
  const { modalData } = useSelector((state) => state.funnelInfo);

  const closeModal = () => {
    dispatch(updateModalData({ show: false, heading: "", content: "" }));
  };

  return (
    <Modal show={modalData.show} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>{modalData.heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body dangerouslySetInnerHTML={{ __html: modalData.content }} />
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={closeModal}>
          Schließen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ReadOnlyModal;
