import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  CenteredInput,
  ZipScreenImage,
  ModernSmContainer,
  CenteredInputLabel,
  CenteredInnerContainer,
  CenteredUserPromptContainer
} from "../ModernVariant.styles";
import {
  blurOnScroll,
  removeFloatingPointNumber
} from "../../../services/utils";
import { useZipcodeAction } from "../../../hooks/useZipcodeAction";

const ModernZipComponent = () => {
  const {
    zipInfo,
    funnelInfo: { funnel }
  } = useSelector((state) => state);
  const { handleUpdateZip, handleMapImage } = useZipcodeAction();
  const country = funnel.companyInfo?.country;
  useEffect(() => {
    handleMapImage({ country });
  }, [country, handleMapImage]);

  return (
    <ModernSmContainer>
      <CenteredUserPromptContainer
        forZip={true}
        className="mt-5 mx-auto bg-white text-center d-flex flex-column justify-content-center align-items-center position-relative"
      >
        <CenteredInnerContainer className="d-flex flex-wrap justify-content-between">
          <div className="d-flex flex-fill align-items-center gap-2 gap-md-3 flex-column flex-md-row">
            <ZipScreenImage src={zipInfo.mapImage} alt="map" />
            <div className="w-100 text-start">
              <CenteredInputLabel className="mt-0">
                {funnel.textElements?.zipCodeScreen || "Postleitzahl"}
              </CenteredInputLabel>
              <CenteredInput
                type="number"
                value={zipInfo.value}
                placeholder="Postleitzahl eingeben"
                onWheel={({ target }) => blurOnScroll(target)}
                onKeyDown={(e) => removeFloatingPointNumber(e)}
                onChange={({ target: { value } }) => handleUpdateZip({ value })}
              />
            </div>
          </div>
        </CenteredInnerContainer>
      </CenteredUserPromptContainer>
    </ModernSmContainer>
  );
};
export default ModernZipComponent;
