import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import { Loader } from "../Loader";
import { Para, ZipImage } from "./ZipCodeForm.styles";
import { useZipcodeAction } from "../../hooks/useZipcodeAction";
import { blurOnScroll, removeFloatingPointNumber } from "../../services/utils";

export const ZipCodeForm = () => {
  const { funnel } = useSelector((state) => state.funnelInfo);
  const { waitingScreen, zipCodeScreen, zipCodeScreenButton } =
    funnel.textElements;
  const baseColor = funnel.design?.baseColor;
  const country = funnel.companyInfo?.country;
  const {
    handleMapImage,
    handleUpdateZip,
    handleCheckZipcode,
    handleZipcodeSubmit
  } = useZipcodeAction();
  const { zipInfo } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);

  const promise = new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, 2000);
  });
  const hideLoader = () => setIsLoading(false);
  const handleZipCodeSubmit = async () => {
    setIsLoading(true);
    promise.then(async (resp) => {
      hideLoader();
      handleZipcodeSubmit();
    });
    await handleCheckZipcode();
  };

  useEffect(() => {
    handleMapImage({ country });
  }, [country, handleMapImage]);
  useEffect(() => {
    setTimeout(() => {
      hideLoader(false);
    }, 2000);
  }, []);

  return (
    <div className="px-3 pt-5 px-md-5">
      {isLoading && (
        <div className="text-center px-5 fs-5 fw-bolder">
          <div style={{ height: "200px", paddingTop: "40px" }}>
            <Loader scale={1.6} color={baseColor} />
          </div>
          <p>{waitingScreen}</p>
        </div>
      )}
      {!isLoading && (
        <>
          <Para>{zipCodeScreen}</Para>
          <div className="row align-items-center">
            <div className="col-12 col-md-4 text-center">
              <ZipImage src={zipInfo.mapImage} alt="map" />
            </div>
            <div className="col-12 col-md-8">
              <Form.Control
                type="number"
                value={zipInfo.value}
                placeholder={"PLZ"}
                onWheel={({ target }) => blurOnScroll(target)}
                onKeyDown={(e) => removeFloatingPointNumber(e)}
                onChange={({ target: { value } }) => handleUpdateZip({ value })}
              />
              <Button
                className="w-100 mt-2"
                onClick={handleZipCodeSubmit}
                disabled={!zipInfo.value}
              >
                {zipCodeScreenButton} ▸
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
