import styled from "styled-components";
import { Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const NextButton = styled(Button)`
  box-shadow: none !important;
  background: ${({ bgcolor }) => `${bgcolor}!important`};
  border-color: ${({ bgcolor }) => `${bgcolor}!important`};
`;

const CustomNextButton = ({
  nextBtnOpts,
  btnType = "button",
  fullWidth = false,
  isDisabled = false,
  isForwarding = false,
  handleClick = () => {}
}) => {
  return (
    <NextButton
      type={btnType}
      disabled={isDisabled}
      onClick={() => {
        if (btnType === "submit") return;
        handleClick();
      }}
      bgcolor={nextBtnOpts.nextButtonBackground || "primary"}
      className={fullWidth ? "w-100" : ""}
    >
      {nextBtnOpts.nextButtonText || "Weiter"}
      {isForwarding ? (
        <Spinner size="sm" className="ms-2" />
      ) : (
        <FontAwesomeIcon className="ms-2" icon={faArrowRight} />
      )}
    </NextButton>
  );
};
export default CustomNextButton;
