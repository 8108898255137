import styled from "styled-components";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import checkedImage from "../../images/check.png";
import errorImage from "../../images/error-icon-32.png";
import { loadAnalyticsAfterLeadTrigger } from "../../services/helper";

const SpacingText = styled.p`
  white-space: break-spaces;
`;
const ThanksButton = styled(Button)`
  border: none;
  font-size: 18px;
  font-weight: 600;
  border-radius: 4px;
  background-color: ${({ background }) => background};
  &:hover {
    background-color: ${({ background }) => background} !important;
  }
`;

export const Greet = ({ leadError }) => {
  const { funnel } = useSelector((state) => state.funnelInfo);
  const thankyouPage = funnel.thankyouPage;
  const baseColor = funnel.design?.baseColor;
  const websiteUrl = funnel.companyInfo?.companyWebsite;
  const thankyouPageScript = funnel.scriptThankyou;
  const {
    thankyouPageHeadline,
    thankyouPageText,
    greetingPhrase,
    websiteButton,
    websiteButtonText
  } = thankyouPage;

  useEffect(() => {
    if (thankyouPageScript) {
      loadAnalyticsAfterLeadTrigger(thankyouPageScript);
    }
  }, [thankyouPageScript]);

  return (
    <div className="px-3 pt-4 px-md-5 text-center">
      <div>
        {!leadError ? (
          <img
            width={"128px"}
            height={"128px"}
            src={checkedImage}
            alt="successfull"
          />
        ) : (
          <img width={"128px"} height={"128px"} src={errorImage} alt="Error" />
        )}
      </div>
      <div className="fs-6 pt-3">
        {!leadError ? (
          <>
            <p className="fw-bold mt-2">{thankyouPageHeadline}</p>
            <SpacingText className="m-0 mb-4">{thankyouPageText}</SpacingText>
            {websiteButton === "Yes" && (
              <a href={websiteUrl}>
                <ThanksButton className="text-white" background={baseColor}>
                  {websiteButtonText}
                </ThanksButton>
              </a>
            )}
            <SpacingText className="fw-bold mt-4">{greetingPhrase}</SpacingText>
          </>
        ) : (
          <>
            <p className="fw-bold">Request Failed</p>
            <p>{leadError}</p>
          </>
        )}
      </div>
    </div>
  );
};
