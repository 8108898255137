import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const CustomContainer = styled.div`
  padding: 60px;
  font-size: 24px;
  background: linear-gradient(to right, rgb(0, 0, 0), rgb(67, 67, 67));
`;
const CheckIcon = styled(FontAwesomeIcon)`
  font-size: 66px;
`;

const AlreadyConvertedContainer = () => {
  return (
    <CustomContainer className="text-white text-center fw-bold rounded">
      <CheckIcon
        icon={faCircleCheck}
        className="text-success bg-white rounded-circle"
      />
      <div className="mt-4 mb-5">Vielen Dank!</div>
      <div className="mb-5">
        <div>Ihre Telefonnummer wurde bereits</div>
        <div>erfolgreich verifiziert</div>
      </div>
      <div>
        <div>Sie werden in Kürze von uns</div>
        <div>kontaktiert.</div>
      </div>
    </CustomContainer>
  );
};
export default AlreadyConvertedContainer;
