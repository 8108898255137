import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: "",
  mapImage: "",
  filterData: {},
  isSubmitted: false
};
export const zipInfoSlice = createSlice({
  name: "zipInfo",
  initialState,
  reducers: {
    updateZipCodeValue: (state, action) => {
      state.value = action.payload;
    },

    updateZipFilterData: (state, action) => {
      state.filterData = action.payload;
    },

    updateMapImage: (state, action) => {
      state.mapImage = action.payload;
    },

    updateIsZipSubmitted: (state, action) => {
      state.isSubmitted = action.payload;
    }
  }
});

export const {
  updateMapImage,
  updateZipCodeValue,
  updateZipFilterData,
  updateIsZipSubmitted
} = zipInfoSlice.actions;
export default zipInfoSlice.reducer;
