import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Option = styled.div`
  position: relative;
  // padding-top: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  &:hover {
    background-color: #efefef;
  }
`;
export const OptionImage = styled.img`
  width: 100%;
  @media (max-width: 767px) {
    zoom: 65%;
  }
`;

export const OptionText = styled.p`
  padding: 10px;
  margin: 0;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  word-wrap: break-word;
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

export const CheckIconContainer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  border-radius: 5px;
  justify-content: end;
  border: 1px solid #e9ecef;
`;

export const CheckIcon = styled(FontAwesomeIcon)`
  color: #0e6dfd;
  font-size: ${({ fs }) => `${fs}px`};
`;
