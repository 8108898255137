import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  SliderRange,
  SliderInput,
  AnswerBadge,
  SliderRangeInfo,
  RangeInputContainer,
  SliderInputContainer,
  CenteredUserPromptContainer
} from "../ModernVariant.styles";
import { blurOnScroll } from "../../../services/utils";

const SliderAnswerComponent = ({ question, setSliderRangeValue }) => {
  const { funnel, sliderValue } = useSelector((state) => state.funnelInfo);
  const { range } = question;
  const [sliderVal, setSliderVal] = useState(range?.min || 0);

  const modernBackgroundOne = funnel.design?.modernBackgroundOne || "";
  const contrastColor = funnel.design?.modernContrastColor || "";
  const taglineBg = funnel.design?.additionalTaglineBackground || "";

  useEffect(() => {
    const delay = setTimeout(
      () => {
        setSliderRangeValue(Number(sliderVal));
      },
      Number(sliderVal) <= range?.min ? 0 : 500
    );
    return () => {
      clearTimeout(delay);
    };
  }, [sliderVal, setSliderRangeValue, range.min]);

  useEffect(() => {
    setSliderVal(sliderValue || range?.min);
    // eslint-disable-next-line
  }, [sliderValue, range]);
  return (
    <CenteredUserPromptContainer className="bg-white text-center d-flex flex-column justify-content-center align-items-center">
      <AnswerBadge className="position-absolute">
        {/* YOUR ANSWER */}
        Ihre Antwort
      </AnswerBadge>
      {/* <div>Some Label</div> */}
      <SliderInputContainer className="position-relative">
        <SliderInput
          min={0}
          type="number"
          max={range?.max}
          value={sliderVal || 0}
          color={modernBackgroundOne}
          border={contrastColor}
          className={`w-100 text-center ${
            sliderVal < range?.min ? "border-danger text-danger" : ""
          }`}
          onWheel={({ target }) => blurOnScroll(target)}
          onChange={({ target: { value } }) => setSliderVal(value)}
          onKeyDown={(e) => {
            if (e.code === "Minus") e.preventDefault();
          }}
        />
        {/* invalid slider value error */}
        {sliderVal < range?.min && (
          <div className="text-danger small position-absolute w-100 mt-1 center fw-bold">
            Ungültig Eingabe
          </div>
        )}
      </SliderInputContainer>
      <div className="w-100 mx-auto">
        <RangeInputContainer className="w-50 mx-auto">
          <SliderRange
            className="w-100"
            hoverColor={taglineBg}
            style={{ color: modernBackgroundOne }}
            value={sliderVal || 0}
            min={range?.min}
            max={range?.max}
            step={range?.step}
            onChange={({ target: { value } }) => setSliderVal(value)}
          />
        </RangeInputContainer>
        <SliderRangeInfo className="w-50 mx-auto d-flex justify-content-between align-items-center">
          <div className="ms-1">{range?.min}</div>
          <div>{range?.max}</div>
        </SliderRangeInfo>
      </div>
    </CenteredUserPromptContainer>
  );
};
export default SliderAnswerComponent;
