import { useSelector } from "react-redux";
import { StickyHeadline, OutcomeContainer } from "../ModernVariant.styles";

const OutcomeComponent = () => {
  const { outcome } = useSelector((state) => state.funnelInfo);
  return (
    <OutcomeContainer className="position-absolute bg-white mx-auto rounded">
      <div className="text-center">
        <StickyHeadline className="sticky-top bg-white py-2 pt-3 mb-0">
          {outcome.outcomeHeadline}
        </StickyHeadline>
        <div className="px-4 pb-4">{outcome.outcomeText}</div>
      </div>
    </OutcomeContainer>
  );
};
export default OutcomeComponent;
