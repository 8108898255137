import { configureStore } from "@reduxjs/toolkit";
import zipInfoSlice from "./slices/zipInfo";
import funnelInfoSlice from "./slices/funnelInfo";

export const store = configureStore({
  reducer: {
    funnelInfo: funnelInfoSlice,
    zipInfo: zipInfoSlice
  }
});
