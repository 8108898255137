import { useState } from "react";
import { useSelector } from "react-redux";
import { useAnswerAction } from "../../../hooks/useAnswerAction";
import { QUESTION_TYPE, THUMBNAIL_TYPE } from "../../../templates/strings";
import { AnswerAsset, AnswerItem, AnswerText } from "../ModernVariant.styles";

const IconAndTextAnsComponent = ({ opt, question, isPreviouslyChecked }) => {
  const { funnel } = useSelector((state) => state.funnelInfo);
  const { handleClickAnswerOption } = useAnswerAction();
  const [isChecked, setIsChecked] = useState(isPreviouslyChecked || false);
  const isMulti = question.isMultiSelectable;

  const toggleCheck = () => setIsChecked(!isChecked);
  const handleOptionClick = async ({ answer }) => {
    handleClickAnswerOption({
      isMulti,
      question,
      answer: opt,
      setIsChecked,
      setSelectedItemId: () => {}
    });
  };

  return (
    <AnswerItem
      bgColorBelow={funnel.design?.modernBackgroundTwo}
      onClick={handleOptionClick}
      className={`bg-white d-flex flex-row flex-md-column align-items-center justify-content-center position-relative ${
        question.type === QUESTION_TYPE.TEXTONLY ? "px-2" : "px-0"
      }`}
      length={question.answers.length}
      textOnlyPadding={question.type === QUESTION_TYPE.TEXTONLY ? true : false}
    >
      {opt.asset && (
        <div>
          <AnswerAsset
            alt="ans-icon"
            ansLength={question?.answers?.length}
            src={`${process.env.REACT_APP_ASSET_CDN_URL}/${opt.asset?.key}/${THUMBNAIL_TYPE.ANSWER_OPTION_ICON}`}
          />
        </div>
      )}
      <input type="checkbox" checked={isChecked} onChange={toggleCheck} />
      <AnswerText className="text-md-center">{opt.text}</AnswerText>
    </AnswerItem>
  );
};
export default IconAndTextAnsComponent;
