import { useDispatch, useSelector } from "react-redux";
import { saveRecordAction, deleteQuestionVisit } from "../services/api";
import { INSIGHTS_TYPE } from "../templates/strings";
import { updateRecordActionState } from "../store/slices/funnelInfo";

export const useRecordAction = () => {
  const dispatch = useDispatch();
  const { funnelInfo, zipInfo } = useSelector((state) => state);
  let senderId = localStorage.getItem("senderId");
  const handleRecordAction = async () => {
    let recordAction = { ...funnelInfo.recordActionState };

    if (!recordAction?.nextInsightRefId) {
      const nextInsightData =
        funnelInfo.currentQuestion === funnelInfo.allQuestions.length
          ? Object.keys(funnelInfo.outcome)?.length
            ? {
                nextInsightRefId: funnelInfo.outcome?.id,
                nextInsightType: funnelInfo.outcome?.outcomeLogic
              }
            : {
                nextInsightRefId: INSIGHTS_TYPE.ZIP,
                nextInsightType: INSIGHTS_TYPE.ZIP
              }
          : {
              nextInsightRefId:
                funnelInfo.allQuestions[funnelInfo.currentQuestion]?._id,
              nextInsightType: INSIGHTS_TYPE.QUESTION
            };
      recordAction.nextInsightRefId = nextInsightData.nextInsightRefId;
      recordAction.nextInsightType = nextInsightData.nextInsightType;
    }
    const payload = {
      funnelId: funnelInfo.funnel.id,
      funnelVersion: funnelInfo.funnel.activeVersion,
      senderId,
      insightRefId: recordAction.insightRefId,
      insightType: recordAction.insightType,
      answerIds: recordAction.answerId,
      nextInsightRefId: recordAction.nextInsightRefId,
      nextInsightType: recordAction.nextInsightType
    };
    if (recordAction.leadId) {
      payload.leadId = recordAction.leadId;
    }
    dispatch(updateRecordActionState({}));
    try {
      await saveRecordAction(payload);
    } catch (error) {
      console.log("Error in saving record action: ", error);
    }
  };

  const handleRemoveAction = async (questionId) => {
    try {
      const payload = {
        senderId,
        funnelId: funnelInfo.funnel.id,
        funnelVersion: funnelInfo.funnel.activeVersion,
        insightId: questionId
      };
      if (
        !questionId &&
        funnelInfo.questionToRemove === funnelInfo.allQuestions.length &&
        !zipInfo.isSubmitted &&
        !funnelInfo.isFormBack
      ) {
        payload.insightId = INSIGHTS_TYPE.ZIP;
      }
      if (funnelInfo.isFormBack && !questionId) {
        payload.insightId = INSIGHTS_TYPE.LEAD;
      }

      await deleteQuestionVisit(payload);
    } catch (error) {
      console.log("Error in saving record action: ", error);
    }
  };

  return { handleRecordAction, handleRemoveAction };
};
