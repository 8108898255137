import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { BtnSpan, NextButton } from "../ModernVariant.styles";

const MoveForwardButton = ({
  btnBg,
  textColor,
  btnHoverBg,
  isDisabled,
  buttonText,
  handleClick,
  fill = true,
  isSubmitting = false
}) => {
  return (
    <NextButton
      className={`outline-0 border-0 text-center position-relative ${
        fill ? "flex-fill" : ""
      }`}
      lightBg={btnBg}
      hoverBg={btnHoverBg}
      onClick={handleClick}
      disabled={isDisabled}
      fill={!fill ? "false" : "true"}
    >
      <div className="d-flex">
        <BtnSpan className="flex-fill position-relative" color={textColor}>
          {buttonText || `Weiter`}
        </BtnSpan>
        <BtnSpan className="position-relative" color={textColor}>
          {isSubmitting ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <FontAwesomeIcon icon={faChevronRight} />
          )}
        </BtnSpan>
      </div>
    </NextButton>
  );
};
export default MoveForwardButton;
