import { useFormContext } from "react-hook-form";
import { FormGroup, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ValidationMessage from "../ValidationMessage";
import { SuggestionText } from "./ContactInfoForm.styles";

const Field = ({ field, suggestion = false }) => {
  const { register } = useFormContext();
  return (
    <FormGroup controlId={field.name} className="mb-4">
      <Form.Label className={(field.hasError || suggestion) && "text-danger"}>
        {field.title}:{(field.hasError || suggestion) && "*"}
      </Form.Label>
      <InputGroup>
        <InputGroup.Text
          className={(field.hasError || suggestion) && "bg-danger text-white"}
        >
          <FontAwesomeIcon icon={field.icon} />
        </InputGroup.Text>
        <Form.Control
          type={field.type}
          {...register(field.name)}
          placeholder={field.placeholder}
        />
      </InputGroup>
      <ValidationMessage message={field.hasError?.message} />
      {suggestion && (
        <SuggestionText className="text-danger fw-bold">
          Did you mean <span className="text-primary">.com</span>
        </SuggestionText>
      )}
    </FormGroup>
  );
};
export default Field;
