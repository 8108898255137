const renderScriptTag = (content) => {
  const scriptTag = document.createElement("script");
  scriptTag.textContent = content;
  document.head.appendChild(scriptTag);
};

export const loadAnalyticsHead = (funnelResponse) => {
  const funnelScriptHead = funnelResponse.data.data.scriptHead;
  if (funnelScriptHead) {
    renderScriptTag(funnelScriptHead);
  }
};

export const loadAnalyticsBody = (funnelResponse) => {
  const funnelScriptBody = funnelResponse.data.data.scriptBody;
  if (funnelScriptBody) {
    const noScriptTag = document.createElement("noscript");
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(funnelScriptBody, "text/html");

    if (parsedHtml.body.childNodes.length) {
      for (let node of parsedHtml.body.childNodes) {
        noScriptTag.append(node);
      }
    }
    document.body.appendChild(noScriptTag);
  }
};

export const loadAnalyticsAfterLeadTrigger = (thankyouPageScript) => {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(thankyouPageScript, "text/html");
  renderScriptTag(parsedHtml.head.innerText);
};
