import { useDispatch, useSelector } from "react-redux";
import {
  OUTCOME_TYPE,
  INSIGHTS_TYPE,
  QUESTION_POSITION
} from "../templates/strings";
import {
  updateOutcome,
  updateSelectedValue,
  restartQuestionnaire,
  updateCurrentQuestion,
  addMultiSelectAnswers,
  clearMultiSelectAnswers,
  updateRecordActionState
} from "../store/slices/funnelInfo";

export const useAnswerAction = () => {
  const dispatch = useDispatch();
  const { funnel, selectedAnswers, allQuestions } = useSelector(
    (state) => state.funnelInfo
  );

  const handleFreeTextSubmit = ({ question, selectedOptionValue }) => {
    dispatch(
      updateRecordActionState({
        insightRefId: question._id,
        insightType: INSIGHTS_TYPE.QUESTION,
        answerId: [question.answers[0]._id]
      })
    );
    dispatch(
      updateSelectedValue({
        qTitle: question.text,
        selectedAnswer: selectedOptionValue
      })
    );
    dispatch(
      updateCurrentQuestion({
        position: question.answers[0].answerAction,
        question
      })
    );
  };

  const handleClick = ({
    answers,
    question,
    nextQuestPos,
    selectedOptionValue,
    shouldDelay = false
  }) => {
    const selectedAnswer = answers.find(
      (ans) => ans.text === selectedOptionValue
    );
    setTimeout(
      () => {
        if (
          nextQuestPos &&
          nextQuestPos !== QUESTION_POSITION.next &&
          nextQuestPos !== QUESTION_POSITION.last &&
          isNaN(nextQuestPos)
        ) {
          const outcomeObj = funnel.outcomes.find(
            (outcome) => outcome.id === nextQuestPos
          );
          if (outcomeObj) {
            switch (outcomeObj.outcomeLogic) {
              case OUTCOME_TYPE.directly:
                dispatch(updateOutcome(outcomeObj));
                dispatch(
                  updateSelectedValue({
                    qTitle: question.text,
                    selectedAnswer: selectedOptionValue
                  })
                );
                dispatch(
                  updateCurrentQuestion({ position: nextQuestPos, question })
                );
                dispatch(
                  updateRecordActionState({
                    insightRefId: question._id,
                    insightType: INSIGHTS_TYPE.QUESTION,
                    answerId: [selectedAnswer._id],
                    nextInsightRefId: outcomeObj.id,
                    nextInsightType: INSIGHTS_TYPE.directly
                  })
                );
                break;
              case OUTCOME_TYPE.end:
                dispatch(updateOutcome(outcomeObj));
                dispatch(
                  updateSelectedValue({
                    qTitle: question.text,
                    selectedAnswer: selectedOptionValue
                  })
                );
                dispatch(
                  updateCurrentQuestion({ position: nextQuestPos, question })
                );
                dispatch(
                  updateRecordActionState({
                    insightRefId: question._id,
                    insightType: INSIGHTS_TYPE.QUESTION,
                    answerId: [selectedAnswer._id]
                  })
                );
                break;
              case OUTCOME_TYPE.restart:
                dispatch(
                  updateRecordActionState({
                    insightRefId: question._id,
                    insightType: INSIGHTS_TYPE.QUESTION,
                    answerId: [selectedAnswer._id],
                    nextInsightRefId: allQuestions[0]._id,
                    nextInsightType: INSIGHTS_TYPE.QUESTION
                  })
                );
                dispatch(restartQuestionnaire());
                break;
              default:
                break;
            }
          }
        } else {
          dispatch(
            updateRecordActionState({
              insightRefId: question._id,
              insightType: INSIGHTS_TYPE.QUESTION,
              answerId: [selectedAnswer._id]
            })
          );
          dispatch(
            updateSelectedValue({
              qTitle: question.text,
              selectedAnswer: selectedOptionValue
            })
          );
          dispatch(updateCurrentQuestion({ position: nextQuestPos, question }));
        }
      },
      shouldDelay ? 400 : 0
    );
  };

  // this method only works on next for multi-select or slider
  const handleNextCustom = ({ sliderVal, question }) => {
    const answersText = sliderVal
      ? sliderVal
      : Object.values(selectedAnswers).map((ansTxt) => ansTxt);
    dispatch(
      updateRecordActionState({
        insightRefId: question._id,
        insightType: INSIGHTS_TYPE.QUESTION,
        answerId:
          Object.keys(selectedAnswers).length > 0
            ? Object.keys(selectedAnswers)
            : sliderVal
              ? [`${question._id}-customAnswer`]
              : ""
      })
    );
    dispatch(
      updateSelectedValue({
        qTitle: question.text,
        selectedAnswer: answersText
      })
    );
    dispatch(
      updateCurrentQuestion({
        position: QUESTION_POSITION.next,
        question
      })
    );
    if (Object.keys(selectedAnswers)?.length) {
      dispatch(clearMultiSelectAnswers());
    }
  };

  const handleClickAnswerOption = ({
    answer,
    isMulti,
    question,
    setIsChecked,
    setSelectedItemId
  }) => {
    if (isMulti) {
      setIsChecked((state) => !state);
      dispatch(addMultiSelectAnswers({ answer }));
    } else {
      setSelectedItemId(answer._id);
      handleClick({
        question: question,
        answers: question.answers,
        selectedOptionValue: answer.text,
        nextQuestPos: answer.answerAction
      });
    }
  };

  return {
    handleNextCustom,
    handleFreeTextSubmit,
    handleClickAnswerOption
  };
};
