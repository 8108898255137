import { useState } from "react";
import {
  faCheckCircle,
  faCheckSquare
} from "@fortawesome/free-solid-svg-icons";
import {
  Option,
  CheckIcon,
  OptionText,
  OptionImage,
  CheckIconContainer
} from "./ImageOption.styles";
import { THUMBNAIL_TYPE } from "../../../templates/strings";
import { useAnswerAction } from "../../../hooks/useAnswerAction";

export const ImageOption = ({ opt, question }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const isMulti = question.isMultiSelectable;

  const { handleClickAnswerOption } = useAnswerAction();

  const handleOptionClick = () => {
    handleClickAnswerOption({
      isMulti,
      question,
      answer: opt,
      setIsChecked,
      setSelectedItemId
    });
  };

  return (
    <Option className="px-2 pt-2" onClick={handleOptionClick}>
      {(selectedItemId === opt._id || isChecked) && (
        <CheckIconContainer>
          <CheckIcon
            fs="26"
            icon={isChecked ? faCheckSquare : faCheckCircle}
            className="mt-2 me-2 p-2"
          />
        </CheckIconContainer>
      )}
      <OptionImage
        src={`${process.env.REACT_APP_ASSET_CDN_URL}/${opt.asset?.key}/${THUMBNAIL_TYPE.ANSWER_OPTION_ICON}`}
        alt="option"
      />
      <OptionText className="fs-7">{opt.text}</OptionText>
    </Option>
  );
};
