import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { BackButton, BtnSpan } from "../ModernVariant.styles";

const MoveBackButton = ({ btnBg, isDisabled = false, onBackClick }) => {
  return (
    <BackButton
      onClick={onBackClick}
      className="outline-0 border-0 text-white text-center position-relative"
      contrastBg={btnBg}
      disabled={isDisabled}
    >
      <div className="d-flex">
        <BtnSpan className="position-relative">
          <FontAwesomeIcon className="ps-2" icon={faChevronLeft} />
        </BtnSpan>
        <BtnSpan className="flex-fill position-relative">Zurück</BtnSpan>
      </div>
    </BackButton>
  );
};
export default MoveBackButton;
