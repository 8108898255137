import { useState } from "react";
import { Col, Form } from "react-bootstrap";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { DivWrapper } from "./TextOption.styles";
import { CheckIcon } from "../ImageOption/ImageOption.styles";
import { useAnswerAction } from "../../../hooks/useAnswerAction";

export const TextOption = ({ opt, question }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const isMulti = question.isMultiSelectable;

  const { handleClickAnswerOption } = useAnswerAction();

  const handleOptionClick = () => {
    handleClickAnswerOption({
      isMulti,
      question,
      answer: opt,
      setIsChecked,
      setSelectedItemId
    });
  };

  return (
    <Col xs="12">
      <DivWrapper
        className="d-flex align-items-center p-2"
        onClick={handleOptionClick}
      >
        {isMulti ? (
          <Form.Check
            className="me-3"
            type="checkbox"
            checked={isChecked}
            onChange={({ target: { checked } }) => {
              setIsChecked(checked);
            }}
          />
        ) : (
          <div>
            {selectedItemId === opt._id ? (
              <CheckIcon fs="18" icon={faCheckCircle} className="me-3" />
            ) : (
              <Form.Check className="me-3" type="radio" />
            )}
          </div>
        )}
        <span className="fs-6 fw-bolder">{opt.text}</span>
      </DivWrapper>
    </Col>
  );
};
