import { useEffect } from "react";
import de from "react-phone-number-input/locale/de";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
  ModernForm,
  FormFieldInfo,
  CenteredInput,
  PhoneInputStyles,
  ModernFormSection,
  FormFieldInfoPara,
  FormFieldInfoSpan,
  CenteredInputLabel,
  ModernFormContainer,
  FormFieldInfoHeading,
  ModerFormBtnContainer,
  ModernFormFieldWrapper,
  ModernFormFieldsSection
} from "../ModernVariant.styles";
import {
  ContactFormBaseSchema,
  ContactFormCompleteSchema
} from "../../../services/validation-schema";
import { PolicySection } from "../../PolicySection";
import { getCountryCodes } from "../../../services/utils";
import { useLeadAction } from "../../../hooks/useLeadAction";
import MoveBackButton from "../MoveButtons/move-back-button";
import { updateDoRemove } from "../../../store/slices/funnelInfo";
import MoveForwardButton from "../MoveButtons/move-forward-button";
import { updateIsZipSubmitted } from "../../../store/slices/zipInfo";
import { SuggestionText } from "../../ContactInfoForm/ContactInfoForm.styles";
import { useEmailDomainConfirmation } from "../../../hooks/useEmailDomainConfirmation";

const ModernLeadForm = ({
  setLeadError,
  isLeadSubmitting,
  setIsLeadSubmitting
}) => {
  const dispatch = useDispatch();
  const { handleEmailDomainConfirmation } = useEmailDomainConfirmation();
  const { checkPhoneNumberValidity, handleSubmitLeadInfo } = useLeadAction();
  const { emailDomainConfirmation, funnel } = useSelector(
    (state) => state.funnelInfo
  );
  const countries = getCountryCodes(funnel.companyInfo?.country);
  const textElements = funnel.textElements;
  const {
    numberCheck,
    fieldTitleName,
    fieldTitleEmail,
    fieldTitlePhone,
    fieldPlaceholderName,
    fieldPlaceholderEmail,
    fieldPlaceholderPhone
  } = textElements;

  const methods = useForm({
    resolver: yupResolver(
      funnel.design?.policyDisplay
        ? ContactFormCompleteSchema
        : ContactFormBaseSchema
    ),
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      policyCheck: false,
      policySectionDisplayDecider: funnel.design?.policyDisplay
    }
  });
  const {
    watch,
    register,
    setError,
    handleSubmit,
    formState: { errors }
  } = methods;
  const emailWatcher = watch("email");

  const onSubmit = (formValues) => {
    const isValidPhoneNumber = checkPhoneNumberValidity({
      phoneNumber: formValues.phone,
      numberCheck,
      setError
    });
    if (!isValidPhoneNumber) return;
    handleSubmitLeadInfo({
      formValues,
      setLeadError,
      setIsLoading: setIsLeadSubmitting
    });
  };

  useEffect(() => {
    handleEmailDomainConfirmation({
      hasError: errors?.email,
      emailValue: emailWatcher
    });
  }, [errors, emailWatcher, handleEmailDomainConfirmation]);

  return (
    <>
      <ModernFormContainer className="mx-auto d-flex flex-column flex-md-row flex-nowrap justify-content-center align-items-stretch">
        <ModernFormSection className="position-relative d-flex flex-column flex-nowrap justify-content-start align-items-start">
          <FormFieldInfo
            className="w-100 text-white d-flex flex-column flex-nowrap justify-content-center align-items-end"
            background={funnel.design?.modernContrastColor || ""}
          >
            <FormFieldInfoHeading className="w-100 mb-0">
              {funnel.design?.modernFormInfoLabelOne ||
                "Nur noch ein letzter Schritt"}
            </FormFieldInfoHeading>
            <FormFieldInfoPara className="m-0 w-100">
              {funnel.design?.modernFormInfoLabelTwo ||
                "Damit wir Sie kontaktieren können, benötigen wir noch einige persönliche Daten."}
            </FormFieldInfoPara>
            <FormFieldInfoSpan className="d-none d-md-inline-flex align-items-center">
              {funnel.design?.modernFormInfoLabelThree || "Formular ausfüllen"}
              <FontAwesomeIcon icon={faArrowRight} />
            </FormFieldInfoSpan>
          </FormFieldInfo>
        </ModernFormSection>
        <ModernFormFieldsSection className="bg-white">
          <FormProvider {...methods}>
            <ModernForm>
              <FormFieldInfoHeading className="w-100 mb-0">
                {funnel.design?.modernFormHeading || "persönliche Daten"}
              </FormFieldInfoHeading>
              <ModernFormFieldWrapper>
                <CenteredInputLabel
                  className={`mt-0 mb-1 ${errors?.name && "text-danger"}`}
                >
                  {`${fieldTitleName || "Name"}*`}
                </CenteredInputLabel>
                <CenteredInput
                  type="text"
                  placeholder={fieldPlaceholderName}
                  {...register("name")}
                />
              </ModernFormFieldWrapper>
              <ModernFormFieldWrapper>
                <CenteredInputLabel
                  className={`mt-0 mb-1 ${
                    (errors?.email || emailDomainConfirmation) && "text-danger"
                  }`}
                >
                  {`${fieldTitleEmail || "E-Mail"}*`}
                </CenteredInputLabel>
                <CenteredInput
                  type="email"
                  placeholder={fieldPlaceholderEmail}
                  {...register("email")}
                />
                {emailDomainConfirmation && (
                  <SuggestionText className="text-danger">
                    Did you mean <span className="text-primary">.com</span>
                  </SuggestionText>
                )}
              </ModernFormFieldWrapper>
              <ModernFormFieldWrapper>
                <CenteredInputLabel
                  className={`mt-0 mb-1 ${errors?.phone && "text-danger"}`}
                >
                  {`${fieldTitlePhone || "Telefonnummer"}*`}
                </CenteredInputLabel>
                <PhoneInputStyles
                  addInternationalOption={false}
                  defaultCountry={countries[0]}
                  countries={countries}
                  name={"phone"}
                  placeholder={fieldPlaceholderPhone}
                  // for translation of country names in German
                  labels={de}
                />
                {errors?.phone?.type === "custom" && (
                  <small className="text-danger pt-1">
                    {errors.phone.message}
                  </small>
                )}
              </ModernFormFieldWrapper>
              <PolicySection />
            </ModernForm>
          </FormProvider>
        </ModernFormFieldsSection>
      </ModernFormContainer>
      <ModerFormBtnContainer className="d-flex flex-row justify-content-between mx-auto">
        <MoveBackButton
          onBackClick={() => {
            dispatch(updateDoRemove({ isForm: true }));
            dispatch(updateIsZipSubmitted(false));
          }}
          btnBg={funnel.design?.modernContrastColor}
          isDisabled={isLeadSubmitting}
        />
        {/* for submit lead */}
        <MoveForwardButton
          isDisabled={
            Boolean(Object.keys(errors).length) ||
            emailDomainConfirmation ||
            isLeadSubmitting
          }
          handleClick={() => handleSubmit(onSubmit)()}
          buttonText={funnel.design?.modernSubmitButtonText || "Senden"}
          fill={false}
          isSubmitting={isLeadSubmitting}
          textColor={funnel.design?.modernSubmitButtonTextColor}
          btnBg={funnel.design?.modernSubmitButtonBackground}
          btnHoverBg={funnel.design?.modernSubmitButtonHoverBackground}
        />
      </ModerFormBtnContainer>
    </>
  );
};
export default ModernLeadForm;
