import { useSelector } from "react-redux";
import {
  StickyHeadline,
  ModernSmContainer,
  CenteredInnerContainer,
  CenteredUserPromptContainer
} from "../ModernVariant.styles";

const ZipCodeErrorComponent = () => {
  const { filterData } = useSelector((state) => state.zipInfo);
  return (
    <ModernSmContainer>
      <CenteredUserPromptContainer className="mt-5 mx-auto bg-white text-center d-flex flex-column justify-content-center align-items-center position-relative">
        <CenteredInnerContainer className="w-100 d-flex flex-wrap justify-content-between">
          <div className="d-flex flex-fill align-items-center gap-3">
            <div className="w-100 text-center">
              <StickyHeadline className="sticky-top bg-white py-2 pt-3 mb-0">
                {filterData.heading}
              </StickyHeadline>
              <div className="px-4 pb-4 fw-normal fs-6">
                {filterData.message}
              </div>
            </div>
          </div>
        </CenteredInnerContainer>
      </CenteredUserPromptContainer>
    </ModernSmContainer>
  );
};
export default ZipCodeErrorComponent;
