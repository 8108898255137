import { parse } from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import {
  updateUrlParams,
  updateUpcomingLeadId,
  updateIsFormSubmitted,
  updateRecordActionState,
  updateReminderEmailFlag
} from "../store/slices/funnelInfo";
import {
  softDeleteUpcomingLeadInfo,
  saveLeadResponse,
  saveUpcomingLeadInfo
} from "../services/api";
import { INSIGHTS_TYPE } from "../templates/strings";
import {
  isValidPhoneNumber,
  normalizeNumber,
  removeWhiteSpaces
} from "../services/utils";
import { SuggestionText } from "../components/ContactInfoForm/ContactInfoForm.styles";

export const useLeadAction = () => {
  const dispatch = useDispatch();
  const { funnelInfo, zipInfo } = useSelector((state) => state);

  const invalidNumberMsg = () => (
    <SuggestionText className="mt-1">
      <FontAwesomeIcon icon={faExclamationCircle} className="me-1" />
      <span>Keine gültige Nummer</span>
    </SuggestionText>
  );

  const checkPhoneNumberValidity = ({ phoneNumber, numberCheck, setError }) => {
    // testing required!
    const isValid = isValidPhoneNumber(phoneNumber, numberCheck);
    if (!isValid) {
      setError("phone", { type: "custom", message: invalidNumberMsg() });
      return false;
    }
    return true;
  };

  const handleSubmitLeadInfo = async ({
    formValues,
    setLeadError,
    setIsLoading,
    upcomingLeadDetails = null
  }) => {
    setIsLoading(true);
    try {
      let submitFormValues = funnelInfo.leadForm.isLandedFromPhoneReminderEmail
        ? upcomingLeadDetails?.formData
        : formValues;
      const splittedName = submitFormValues.name.split(" ");
      const firstName = splittedName[0];
      // remove whitespaces except actual ones
      const lastName =
        removeWhiteSpaces({ string: splittedName.slice(1).join(" ") }) || "";
      dispatch(
        updateUrlParams({
          email: submitFormValues.email,
          firstName,
          lastName,
          phone: normalizeNumber(submitFormValues.phone)
        })
      );
      const serachParams = parse(window.location.search);
      const fbClId = upcomingLeadDetails?.fbclid || serachParams.fbclid;
      const dynamicSource = serachParams.source?.toLowerCase();
      const testMode =
        upcomingLeadDetails?.testMode || serachParams.testMode === "true"
          ? true
          : false;
      const { funnel } = funnelInfo;
      const leadPayload = {
        clickId: fbClId,
        testMode,
        sourceUrl: window.location.href,
        leadService: funnel.leadService,
        lead: {
          funnelId: funnel._id,
          funnelVersion: funnel.activeVersion,
          funnelCustomer: funnel.customer,
          productName: funnel.leadServiceProductName || funnel.category.name,
          leadDetails: {
            leadName: submitFormValues.name,
            leadEmail: submitFormValues.email,
            leadPhone: normalizeNumber(submitFormValues.phone),
            leadZip: upcomingLeadDetails?.leadZip || zipInfo.value,
            leadType: upcomingLeadDetails?.leadType || funnel.general.funnelType
          },
          adDetails: {
            campaign_id: serachParams.am_campaign_id,
            campaign_name: serachParams.am_campaign_name,
            adset_id: serachParams.am_adset_id,
            adset_name: serachParams.am_adset_name,
            ad_id: serachParams.am_ad_id,
            ad_name: serachParams.am_ad_name
          },
          responses: upcomingLeadDetails?.responses || funnelInfo.qResponses,
          source: dynamicSource
        }
      };
      const response = await saveLeadResponse(leadPayload);
      dispatch(
        updateRecordActionState({
          insightRefId: INSIGHTS_TYPE.LEAD,
          insightType: INSIGHTS_TYPE.LEAD,
          nextInsightRefId: INSIGHTS_TYPE.LEAD_CREATED,
          nextInsightType: INSIGHTS_TYPE.LEAD_CREATED,
          leadId: response.data.data._id
        })
      );
      // after save lead response check if there is an upcoming lead(creating a lead with 2-steps enabled), then remove it
      if (funnelInfo.leadForm.upcomingLeadId) {
        try {
          await softDeleteUpcomingLeadInfo(funnelInfo.leadForm.upcomingLeadId);
        } catch (error) {
          console.log("Error in deleting upcoming lead info", error);
        }
      }
    } catch (error) {
      setLeadError(error.message);
    } finally {
      // setTimeout for display loader
      setTimeout(() => {
        setIsLoading(false);
        dispatch(updateIsFormSubmitted(!funnelInfo.leadForm.isSubmitted));
        dispatch(updateUpcomingLeadId(null));
        dispatch(updateReminderEmailFlag(false));
      }, 3000);
    }
  };

  const handleSubmitUpcomingLeadInfo = async ({
    formValues,
    setDisplayNext,
    setIsSubmitting,
    submissionError
  }) => {
    setIsSubmitting(true);
    try {
      const serachParams = parse(window.location.search);
      const fbClId = serachParams.fbclid || null;
      const testMode = serachParams.testMode === "true" ? true : false;
      const { funnel, qResponses } = funnelInfo;
      const leadPayload = {
        funnelId: funnel._id,
        funnelVersion: funnel.activeVersion,
        funnelCustomer: funnel.customer,
        leadName: formValues.name,
        leadEmail: formValues.email,
        leadZip: zipInfo.value,
        leadType: funnel.general.funnelType,
        adDetails: {
          campaign_id: serachParams.am_campaign_id,
          campaign_name: serachParams.am_campaign_name,
          adset_id: serachParams.am_adset_id,
          adset_name: serachParams.am_adset_name,
          ad_id: serachParams.am_ad_id,
          ad_name: serachParams.am_ad_name
        },
        testMode,
        fbclid: fbClId,
        responses: qResponses
      };
      const response = await saveUpcomingLeadInfo(leadPayload);
      const upcomingLeadId = response.data.data._id;
      dispatch(
        updateRecordActionState({
          insightRefId: INSIGHTS_TYPE.LEAD_STEP_ONE,
          insightType: INSIGHTS_TYPE.LEAD_STEP_ONE,
          nextInsightRefId: INSIGHTS_TYPE.LEAD,
          nextInsightType: INSIGHTS_TYPE.LEAD
        })
      );
      dispatch(updateUpcomingLeadId(upcomingLeadId));
      setTimeout(() => {
        setDisplayNext(true);
        setIsSubmitting(false);
      }, 3000);
    } catch (error) {
      console.log("Error: ", error);
      setTimeout(() => {
        submissionError(error.message);
        setIsSubmitting(false);
      }, 3000);
    }
  };

  return {
    handleSubmitLeadInfo,
    checkPhoneNumberValidity,
    handleSubmitUpcomingLeadInfo
  };
};
