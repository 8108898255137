import italienMapImage from "../../images/Italien.png"; // Italy
import schweizMapImage from "../../images/Schweiz.png"; // Switzerland
import BelgiumMapImage from "../../images/Belgium.png"; // Belgium
import frankreichMapImage from "../../images/Frankreich.png"; // France
import luxemburgMapImage from "../../images/Luxemburg.png"; // Luxemburg
import deutschlandMapImage from "../../images/Deutschland.png"; // Germany
import niederlandeMapImage from "../../images/Niederlande.png"; // Netherlands
import osterreichMapImage from "../../images/Osterreich.png"; // Austria
import polenMapImage from "../../images/poland.png"; // Poland
import spainMapImage from "../../images/spain.png"; // Spanin
import deutschlandÖsterreichSchweizMapImage from "../../images/Deutschland_Österreich_Schweiz.png"; // Germany_Austria_Switzerland
import deutschlandItalienMapImage from "../../images/Deutschland_Italien.png"; // Germany_Italy
import deutschlandSchweizMapImage from "../../images/Deutschland_Schweiz.png"; // Germany_Switzerland
import deutschlandFrankreichMapImage from "../../images/Deutschland_Frankreich.png"; // Germany_France
import deutschlandÖsterreichMapImage from "../../images/Deutschland_Österreich.png"; // Germany_Austria
import deutschlandLuxemburgMapImage from "../../images/Deutschland_Luxemburg.png"; // Germany_Luxembourg
import deutschlandNiederlandeMapImage from "../../images/Deutschland_Niederlande.png"; // Germany_Netherlands
import deutschlandSpanienMapImage from "../../images/Deutschland_Spanien.png"; // Germany_Spain

export const countryMapHash = {
  belgium: BelgiumMapImage,
  germany: deutschlandMapImage,
  france: frankreichMapImage,
  italy: italienMapImage,
  luxemburg: luxemburgMapImage,
  netherlands: niederlandeMapImage,
  switzerland: schweizMapImage,
  austria: osterreichMapImage,
  poland: polenMapImage,
  spain: spainMapImage,
  germanyAndAustriaAndSwitzerland: deutschlandÖsterreichSchweizMapImage,
  germanyAndfrance: deutschlandFrankreichMapImage,
  germanyAnditaly: deutschlandItalienMapImage,
  germanyAndluxemburg: deutschlandLuxemburgMapImage,
  germanyAndnetherlands: deutschlandNiederlandeMapImage,
  germanyAndaustria: deutschlandÖsterreichMapImage,
  germanyAndswitzerland: deutschlandSchweizMapImage,
  germanyAndspain: deutschlandSpanienMapImage
};
