import React from "react";
import { useSelector } from "react-redux";
import { THUMBNAIL_TYPE } from "../../templates/strings";
import { ImageContainer, HeaderImage } from "./Header.styles";

export const Header = () => {
  const { design } = useSelector((state) => state.funnelInfo.funnel);
  const scale = design?.companyLogoScale;
  const imgSrc = design?.companyLogo?.key;
  const background = design?.companyLogoBackground;
  const logoBgDisplay = design?.companyLogoBackgroundDisplay;
  return (
    <div>
      <ImageContainer background={background} logoBgDisplay={logoBgDisplay}>
        <HeaderImage
          scale={scale}
          alt="head image"
          src={`${process.env.REACT_APP_ASSET_CDN_URL}/${imgSrc}/${THUMBNAIL_TYPE.COMPANY_LOGO}`}
        />
      </ImageContainer>
    </div>
  );
};
