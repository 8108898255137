import { useSelector } from "react-redux";
import ReadOnlyModal from "../../Modal";
import { FooterContainer } from "../ModernVariant.styles";
import AnchorElement from "../../CommonVariantComponents/anchor-element";

const FooterComponent = () => {
  const { funnel } = useSelector((state) => state.funnelInfo);
  const {
    companyName,
    imprintUrl,
    imprintContent,
    dataProtectionUrl,
    dataProtectionContent
  } = funnel.companyInfo;
  return (
    <>
      <FooterContainer className="d-flex align-items-center justify-content-between w-100 px-4 position-absolute">
        <div>
          © {companyName} {new Date().getFullYear()}
        </div>
        <div>
          <AnchorElement
            classes="me-3"
            anchorText="Impressum"
            modalData={{
              heading: "Impressum",
              content: imprintContent
            }}
            href={imprintContent ? void 0 : imprintUrl}
          />
          <AnchorElement
            anchorText="Datenschutz"
            modalData={{
              heading: "Datenschutzerklärung",
              content: dataProtectionContent
            }}
            href={dataProtectionContent ? void 0 : dataProtectionUrl}
          />
        </div>
      </FooterContainer>
      <ReadOnlyModal />
    </>
  );
};
export default FooterComponent;
