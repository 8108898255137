import styled from "styled-components";
import { Form } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import CustomNextButton from "../CustomNextButton";
import ValidationMessage from "../ValidationMessage";
import { useAnswerAction } from "../../hooks/useAnswerAction";
import { AnswerFormSchema } from "../../services/validation-schema";

const ValidationContainer = styled.div`
  height: 30px;
`;

const FreeTextForm = ({ question, nextBtnOpts }) => {
  const methods = useForm({
    resolver: yupResolver(AnswerFormSchema),
    defaultValues: { answer: "" }
  });
  const {
    formState: { errors },
    register,
    handleSubmit
  } = methods;

  const { handleFreeTextSubmit } = useAnswerAction();

  const onSubmit = (formValues) => handleSubmitAnswer(formValues);
  const handleSubmitAnswer = (formData) => {
    handleFreeTextSubmit({ question, selectedOptionValue: formData.answer });
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(event) => {
          handleSubmit(onSubmit)(event);
        }}
      >
        <Form.Group controlId="Category Name">
          <Form.Label className={errors?.answer && "text-danger"}>
            Antwort:
            {errors?.answer && "*"}
          </Form.Label>
          <Form.Control
            type="text"
            name="answer"
            placeholder="Schreiben Sie hier Ihre Antwort rein"
            className={`form-control ${errors?.answer && "border-danger"}`}
            {...register("answer")}
          />
        </Form.Group>
        <ValidationContainer>
          {errors?.answer && (
            <ValidationMessage message={errors?.answer.message} />
          )}
        </ValidationContainer>
        <div className="mt-4 d-flex justify-content-end">
          <CustomNextButton btnType="submit" nextBtnOpts={nextBtnOpts} />
        </div>
      </form>
    </FormProvider>
  );
};
export default FreeTextForm;
